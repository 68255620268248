import { isEmail, isNumber, isValidUkPostCode, sameAs, validName } from '@/utils/helper';

export const houseNumberValidationRules = () => ([
  (v) => !!v || 'House No. is required',
  (v) => (!!v && v.length > 1) || 'House No. Should greater than 1',
  (v) => (!!v && v.length < 10) || 'House No. Should less than 10'
]);

export const roadNumberValidationRules = () => ([
  (v) => !!v || 'Road is required',
  (v) => (!!v && v.length > 1) || 'Road Should greater than 1',
  (v) => (!!v && v.length < 30) || 'Road not more than 30 characters'
]);

export const cityValidationRules = () => ([
  (v) => !!v || 'City  is required',
  (v) => (!!v && v.length > 3) || 'City should greater than 3 character',
  (v) => (!!v && v.length < 35) || 'City should not greater than 35 character',
]);

export const countyValidationRules = () => ([
  (v) => !!v || 'County is required',
  (v) => (!!v && v.length > 3) || 'County should greater than 3 character',
  (v) => (!!v && v.length < 35) || 'County should not greater than 35 character'
]);

export const postCodeValidationRules = () => ([
  (v) => !!v || 'Post code is required',
  (v) => (!!v && v.length > 3) || 'Postcode should greater than 3 character',
  (v) => (!!v && v.length < 20) || 'Postcode should not greater than 20 character',
  (v) => (!!v && isValidUkPostCode(v)) || 'This post code is not a valid UK post code'
]);

export const addressTypeValidation = () => ([
  (v) => !!v || 'Address Type is required',
  (v) => (!!v && (v === 'Work' || v === 'Home')) || 'Invalid address type'
]);

export const userFirstNameRule = () => ([
  (v) => !!v || 'First Name is required',
  (v) => (!!v && validName(v))|| 'First name is invalid',
  (v) => (v && v.length > 3) || 'First name must be greater than 3 characters',
  (v) => (v && v.length < 20) || 'First name must not greater than 20 characters',
]);

export const userLastNameRule = () => ([
  (v) => !!v || 'First Name is required',
  (v) => (!!v && validName(v))|| 'Last name is invalid',
  (v) => (v && v.length > 3) || 'Last name must be greater than 3 characters',
  (v) => (v && v.length < 20) || 'Last name must not greater than 20 characters',
]);

export const userEmailRule = () => ([
  (v) => !!v || 'Email is required',
  (v) => (!!v && isEmail(v)) || 'Email Must be email'
]);

export const userPhoneNumberRule = () => ([
  (v) => !!v || 'Phone number is required',
  (v) => (!!v && v.length > 8) || 'Phone number must be at least 8 or more character',
  (v) => (!!v && isNumber(v)) || 'Phone number must be number'
]);

export const userPasswordRule = (value, confirming = false) => {

  let rules = [
    (v) => !!v || 'Password is required',
    (v) => (!!v && v.length > 7) || 'Password must be 8 or more character',
    (v) => (!!v && v.length <= 50) || 'Password must be 50 or less character',
  ];


  if(confirming){
    rules.push((v) => (!!v && sameAs(value, v)) || 'Password and confirm password must be same')
  }

  return rules;
};
