<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">{{ title }}</v-card-title>
    <v-card-text> {{ message }}</v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          class="mr-2"
          color="primary"
          @click="deleteAction('cancel')"
        >
          {{ cancelButtonText }}
        </v-btn>
        <v-btn
          :loading="isDataSubmitting"
          color="primary"
          @click="deleteAction('confirmed')"
        >
          {{ confirmButtonText }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DeleteConfirmation',
  props: {
    title: {
      type: String,
      default: 'Are You Sure?'
    },
    message: {
      type: String,
      default: 'Are You Sure You Want To Delete? \n This operation is permanent and cannot be undone.'
    },

    confirmButtonText: {
      type: String,
      default: 'Delete'
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },

    loading: {
      type: Boolean,
      default: false,
    }

  },
  data: () => ({
    isDataSubmitting: false
  }),
  watch: {
    loading: {
      handler(nv) {
        this.isDataSubmitting = nv;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    deleteAction(type) {
      this.$emit('action', type);
    }
  },
};
</script>
